import Loop from "../../assets/images/preloader.svg"

const Preloader = ({size = 40}) => {
    return (
        <div>
            <img src={Loop} alt="Preloader" width={`${size}`}/>
        </div>
    )
}

export default Preloader