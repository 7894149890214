import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import { useNavigate, useParams } from "react-router-dom"
import Preloader from "../../../../components/Preloader"
import { getSeller, getSellerCompanies, setSeller, setSellerCompanies, updateSeller } from "../../../../store/SettingsStore/seller"

const SellerPage = () => {

    const {id} = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const seller = useSelector((state) => state.seller.seller)

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle('Продавцы'))
        dispatch(setPageTitleBack({name: 'Продавцы', link: `/app/settings/sellers`}))
    }, [dispatch])

    useEffect(() => {
        dispatch(getSeller(id))
        dispatch(getSellerCompanies(id))
    },[dispatch, id])

    useEffect(() => {
        return () => {
            dispatch(setSeller(null))
            dispatch(setSellerCompanies([]))
        }
    }, [dispatch])

    if (!seller) {
        return <Preloader />
    }

    return (
        <div>
            <CompanyInfo seller={seller} />
            <LinkedCompanies />
            <div>
                <h2>Найденные продукты</h2>
                <div>
                    <ul>
                        <li onClick={() => navigate(`c/0`)}><span className="link">Открыть</span></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default SellerPage

const CompanyInfo = ({seller}) => {
    const [e, setE] = useState(false)

    const handler = () => {
        setE(!e)
    }

    return(
        <div>
            <h2>Контактная информация</h2>
            <h3>{seller.name}</h3>
            {e ? <CreateSeller seller={seller} handler={handler} /> : <ShowSeller seller={seller} handler={handler} />}
        </div>
    );
}

const ShowSeller = ({seller, handler}) => {
    return (
        <div>
            <div>Контактное лицо - {seller.person}</div>
            <div>Телефон - {seller.phone}</div>
            <div>Эл. почта - {seller.email}</div>
            <div>Комментарий - {seller.comment}</div>
            <div><span className="link" onClick={handler}>Изменить</span></div>
        </div>
    )
}

const CreateSeller = ({seller, handler}) => {
    const dispatch = useDispatch()

    const [person, setPerson] = useState(seller.person);
    const [phone, setPhone] = useState(seller.phone);
    const [email, setEmail] = useState(seller.email);
    const [comment, setComment] = useState(seller.comment);

    const handlerSubmit = (e) => {
        e.preventDefault();
        dispatch(updateSeller(seller.ID, {person, phone, email, comment}))
    }

    return (
        <div style={{maxWidth: 500}}>
            <form onSubmit={handlerSubmit}>
                <div className="form-row">
                    <label htmlFor="person">
                            <div className="form-label">Контактное лицо</div>
                            <input id="person"
                                name="person"
                                type="text"
                                className="text-field"
                                required
                                value={person}
                                onChange={e => setPerson(e.target.value)} />
                    </label>
                </div>
                <div className="form-row">
                    <label htmlFor="phone">
                            <div className="form-label">Телефон</div>
                            <input id="phone"
                                name="phone"
                                type="text"
                                className="text-field"
                                required
                                value={phone}
                                onChange={e => setPhone(e.target.value)} />
                    </label>
                </div>
                <div className="form-row">
                    <label htmlFor="email">
                            <div className="form-label">Эл. почта</div>
                            <input id="email"
                                name="email"
                                type="text"
                                className="text-field"
                                required
                                value={email}
                                onChange={e => setEmail(e.target.value)} />
                    </label>
                </div>
                <div className="form-row mb-24">
                    <label htmlFor="comment">
                        <div className="form-label">Комментарий</div>
                        <textarea id="comment"
                                name="comment"
                                type="text"
                                className="text-field"
                                value={comment}
                                onChange={e => setComment(e.target.value)} />
                    </label>
                </div>
                <div className="mb-12">
                    <button className="button">Сохранить</button>
                </div>
                <div><span className="link" onClick={handler}>Свернуть</span></div>
            </form>
        </div>
    )
}

const LinkedCompanies = () => {
    
    const companies = useSelector((state) => state.seller.companies)
    
    const navigate = useNavigate()

    if (companies.length === 0) {
        return (
            <>
                <h2>Связанные компании</h2>
                <div>Нет связаных</div>
            </>
        )
    }

    return (
        <div>
            <h2>Связанные компании</h2>
            <div>
                <ul>
                    {companies.map(e => <li key={e.company.ID} onClick={() => navigate(`c/${e.company.ID}`)}><span className="link">{e.company.name}</span></li>)}
                </ul>
            </div>
        </div>
    )
}
