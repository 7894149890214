import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAppGraphCount, getAppGraphSum, getAppPieCount, getAppPieSum, getCount, getSalesCount, setAppPeriod } from "../../../store/AppStore/appData"
import { useNavigate } from "react-router-dom"
import "./style.css"
import Sellers from "../../../assets/images/sellers.svg"
import Prods from "../../../assets/images/prods.svg"
import Dump from "../../../assets/images/dump.svg"
import Sales from "../../../assets/images/sales.svg"
import SalesGraph from "../../../components/Graph/SalesGraph"
import PieGraph from "../../../components/Graph/PieApp"

const AppPage = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCount("products"))
        dispatch(getCount("sellers"))
        dispatch(getCount("dumping"))
        dispatch(getSalesCount(null))
    }, [dispatch])

    return (
        <>
            <div className="flex" style={{flexWrap: 'wrap', margin: -10, marginBottom:12, justifyContent: 'space-between'}}>
                <div style={{flex: "1 0 25%"}}><SellersBlock /></div>
                <div style={{flex: "1 0 25%"}}><ProductsBlock /></div>
                <div style={{flex: "1 0 25%"}}><DumpingBlock /></div>
                <div style={{flex: "1 0 25%"}}><SalesBlock /></div>
            </div>
            <GraphBlock />
        </>
    )
}

export default AppPage

const SellersBlock = () => {

    const navigate = useNavigate()
    const sellersCount = useSelector((state) => state.appData.sellersCount)

    return (
        <div className="appblock seller"
            onClick={() => navigate("sellers")}>
            <div className="appblock-inner">
                <h3>Продавцы</h3>
                <div>
                    <span className="appblock-number">{sellersCount}</span>
                    <span className="appblock-desc">компаний</span>
                </div>

            </div>
            
            <div className="appblock-icon"><img src={Sellers} alt="User" /></div>
        </div>
    )
}

const ProductsBlock = () => {

    const navigate = useNavigate()
    const productsCount = useSelector((state) => state.appData.productsCount)

    return (
        <div className="appblock product"
            onClick={() => navigate("products")}>
            <div className="appblock-inner">
                <h3>Продукция</h3>
                <div>
                    <span className="appblock-number">{productsCount}</span>
                    <span className="appblock-desc">единиц</span>
                </div>
            </div>

            <div className="appblock-icon"><img src={Prods} alt="User" /></div>
        </div>
    )
}

const DumpingBlock = () => {

    const navigate = useNavigate()
    const dumpingCount = useSelector((state) => state.appData.dumpingCount)

    const [sum, setSum] = useState(0)

    useEffect(() => {
        let a, b
        a = dumpingCount.forCompanies?? 0
        b = dumpingCount.nullCompanies?? 0
        setSum(a + b)
    }, [dumpingCount])

    return (
        <div className="appblock dumping"
            onClick={() => navigate("dumping")}>
            <div className="appblock-inner">
                <h3>Демпинг</h3>
                <div>
                    <span className="appblock-number">{sum}</span>
                    <span className="appblock-desc">нарушений</span>
                </div>
            </div>

            <div className="appblock-icon"><img src={Dump} alt="User" /></div>
        </div>
    )
}

const SalesBlock = () => {

    const navigate = useNavigate()
    const salesCount = useSelector((state) => state.appData.salesCount)

    return (
        <div className="appblock sales"
            onClick={() => navigate("sales")}>
            <div className="appblock-inner">
                <h3>Продажи</h3>
                <div>
                    <span className="appblock-number">{salesCount.count}</span>
                    <span className="appblock-desc">c {salesCount.from}</span>
                </div>
            </div>

            <div className="appblock-icon"><img src={Sales } alt="User" /></div>
        </div>
    )
}

const GraphBlock = () => {
    return (
        <>
            <div className="flex" style={{flexWrap: 'wrap', margin: -10, marginBottom:12, justifyContent: 'space-between'}}>
                <div style={{flex: "1 0 75%"}}><GraphCountBlock type={"count"} title="Количество заказов" t="шт." menu={true} /></div>
                <div style={{flex: "1 0 25%"}}><GraphSumBlock type={"count"} title="Доли маркетплейсов" t="шт." /></div>
            </div>
            <div className="flex" style={{flexWrap: 'wrap', margin: -10, marginBottom:12, justifyContent: 'space-between'}}>
                <div style={{flex: "1 0 75%"}}><GraphCountBlock type={"sum"} title="Сумма заказов" t="руб." /></div>
                <div style={{flex: "1 0 25%"}}><GraphSumBlock type={"sum"} title="Доли маркетплейсов" t="руб." /></div>
            </div>
        </>
    )
}

const SelectorStep = () => {

    const dispatch = useDispatch()

    const period = useSelector((state) => state.appData.period)

    const [list, setList] = useState([])

    const handler = (e) => {
        dispatch(setAppPeriod(e))
    }

    useEffect(() => {
        const steps = [
            {
                name: 'day',
                label: "По дням"
            },
            {
                name: 'week',
                label: "По неделям"
            },
            {
                name: 'month',
                label: "По месяцам"
            }
        ]

        let tmp = []

        steps.forEach((e, i) => {
            let data = {
                name: e.name,
                label: e.label,
                active: e.name === period
            }

            tmp.push(data)
        })

        setList(tmp)
    }, [period])

    return (
        <div className="button-group">
            {list.map((e, i) => <button className={`button-group-item ${e.active}`} key={i} onClick={() => handler(e.name)}>{e.label}</button>)}
        </div>
    )
}

const GraphCountBlock = ({type, menu, title, t}) => {

    const dispatch = useDispatch()

    const period = useSelector((state) => state.appData.period)
    const graphCount = useSelector((state) => state.appData.graphCount)
    const graphSum = useSelector((state) => state.appData.graphSum)

    useEffect(() => {
        if (type === "count") {
            dispatch(getAppGraphCount(period))
        } else {
            dispatch(getAppGraphSum(period))
        }
    }, [period, dispatch, type])

    return (
        <div className="appgraphblock">
            <div className="appblock-inner">
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <h3>{title}, {t}</h3>
                    {menu ? <SelectorStep /> : null}
                </div>
                <SalesGraph data={type === "count" ? graphCount : graphSum} />
            </div>
        </div>
    )
}

const GraphSumBlock = ({type, title, t}) => {

    const dispatch = useDispatch()

    const period = useSelector((state) => state.appData.period)
    const pieCount = useSelector((state) => state.appData.pieCount)
    const pieSum = useSelector((state) => state.appData.pieSum)

    useEffect(() => {
        if (type === "count") {
            dispatch(getAppPieCount(period))
        } else {
            dispatch(getAppPieSum(period))
        }
    }, [period, dispatch, type])

    return (
        <div className="appgraphblock">
            <div className="appblock-inner">
                <div>
                    <h3 style={{marginBottom: 0}}>{title}, {t}</h3>
                    <div>последние 30 дней</div>
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <PieAndPercent data={type === "count" ? pieCount : pieSum} />
                    <div style={{display: "flex", marginTop: 20}}>
                        <div className="shopDot oz">Ozon</div>
                        <div className="shopDot wb">Wildberries</div>
                        <div className="shopDot ym">ЯндексМаркет</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const labels = ['Ozon', 'Wildberries', 'Yandex Market']

const PieAndPercent = ({data}) => {

    const [pie, setPie] = useState(null)
    const [percents, setPercents] = useState(null)

    useEffect(() => {
        let datas = [0, 0, 0]
        let pcsum = 0
        let pcs = [0, 0, 0]

        if (data && data.data) {
            data.data[0].data.forEach(element => {
                labels.forEach((e, i) => {
                  if (e === element.label) {
                    datas[i] = element.value
                    pcsum += element.value
                  }
                });
              });

              setPie({
                labels: labels,
                datasets: [
                  {
                    label: ' ',
                    data: datas,
                    backgroundColor: [
                      '#0057F5',
                      '#B02675',
                      '#FCE000',
                    ],
                    borderColor: [
                      '#fff',
                      '#fff',
                      '#fff',
                    ],
                    borderWidth: 3,
                  },
                ],
              })
        }

        if (pcsum > 0) {
            datas.forEach((e, i) => {
                pcs[i] = Math.round(((e * 100) / pcsum) * 10) / 10 
            });
        }

        setPercents(pcs)
    }, [data])

    if (!pie || !percents) {
        return null
    }

    return (
        <>
            <div className="flex" style={{width: "100%", justifyContent: "space-between"}}>
                    {percents.map((e, i) => 
                        <div key={i} className="percents-block">
                            <div style={{fontSize: 14}}>{labels[i]}</div>
                            <div style={{fontWeight: 600, fontSize: 24}}>{e}%</div>
                        </div>
                    )}
                </div>
            <div style={{width: 180, paddingTop: 10, paddingBottom: 10, paddingLeft: 0, paddingRight: 0}}>
                <PieGraph data={pie} />
            </div>
        </>
    )
}