import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import SalesGraphNew from "../../../../components/Graph/SalesGraph/new_index"
import { getProductsDataAnalyseGraph, setProductsDataAnalyseGraphPeriod } from "../../../../store/AppStore/productsData"

const SellerMainGraphic = () => {

    const dispatch = useDispatch()

    const selected = useSelector((state) => state.productsData.selected)
    const analyseGraphic = useSelector((state) => state.productsData.analyseGraphic)
    const analyseGraphicPeriod = useSelector((state) => state.productsData.analyseGraphicPeriod)

    useEffect(() => {
        if (selected.length > 0) {
            dispatch(getProductsDataAnalyseGraph(analyseGraphicPeriod, selected))
        }
    }, [analyseGraphicPeriod, dispatch, selected])

    return (
        <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, marginBottom: 24}}>
            <SelectorStep />
            <SalesGraphNew data={analyseGraphic} />
        </div>
    )
}

export default SellerMainGraphic

const SelectorStep = () => {

    const dispatch = useDispatch()

    const period = useSelector((state) => state.productsData.analyseGraphicPeriod)

    const [list, setList] = useState([])

    const handler = (e) => {
        dispatch(setProductsDataAnalyseGraphPeriod(e))
    }

    useEffect(() => {
        const steps = [
            {
                name: 'day',
                label: "По дням"
            },
            {
                name: 'week',
                label: "По неделям"
            },
            {
                name: 'month',
                label: "По месяцам"
            }
        ]

        let tmp = []

        steps.forEach((e, i) => {
            let data = {
                name: e.name,
                label: e.label,
                active: e.name === period
            }

            tmp.push(data)
        })

        setList(tmp)
    }, [period])

    return (
        <div className="button-group">
            {list.map((e, i) => <button className={`button-group-item ${e.active}`} key={i} onClick={() => handler(e.name)}>{e.label}</button>)}
        </div>
    )
}