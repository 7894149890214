import { useParams } from "react-router-dom"
import SellerCompanyProduct from "./SellerCompanyProduct"
import SellerNullProduct from "./SellerNullProduct"

const SellerCompanyProductPage = () => {

    const {cid} = useParams()

    if (cid !== "0") {
        return <SellerCompanyProduct />
    } else {
        return <SellerNullProduct />
    }
}

export default SellerCompanyProductPage