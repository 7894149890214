import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
axios.defaults.withCredentials = true;

export const sellersData = createSlice({
    name: 'sellersData',
    initialState: {
        list : null,
        selected: [],
        analyseGraphicPeriod: "week",
        analyseGraphic: null,
        analyseRoundByProducts: null,
        analyseTableByProducts: null,
        analyseRoundBySales: null,
        analyseTableBySales: null
    },
    reducers: {
        setSellersDataList: (state, action) => {
            state.list = action.payload
        },
        setSellersDataSelected: (state, action) => {
            state.selected = action.payload
        },
        setSellersDataAnalyseGraph: (state, action) => {
            state.analyseGraphic = action.payload
        },
        setSellersDataAnalyseGraphPeriod: (state, action) => {
            state.analyseGraphicPeriod = action.payload
        },
        setAnalyseRoundByProducts: (state, action) => {
            state.analyseRoundByProducts = action.payload
        },
        setAnalysetableByProducts: (state, action) => {
            state.analyseTableByProducts = action.payload
        },
        setAnalyseRoundBySales: (state, action) => {
            state.analyseRoundBySales = action.payload
        },
        setAnalysetableBySales: (state, action) => {
            state.analyseTableBySales = action.payload
        }
    }
})

export const { setSellersDataList, setSellersDataSelected, setSellersDataAnalyseGraph, setSellersDataAnalyseGraphPeriod, setAnalyseRoundByProducts, setAnalysetableByProducts, setAnalyseRoundBySales, setAnalysetableBySales } = sellersData.actions

export const getSellersDataList = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/analyse/sellers/list`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setSellersDataList(response.data.result.data))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getSellersDataAnalyseGraph = (period, selected) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/sellers/graph`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
           "period" : period,
           "sellers" : selected
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setSellersDataAnalyseGraph(response.data.result))
        })
        .catch((error) => {
            dispatch(setSellersDataAnalyseGraph(null))
        });
}

export const getAnalyseRoundByProducts = (f, t, selected) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/sellers/products/graph`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            from: f,
            to: t,
            sellers: selected
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setAnalyseRoundByProducts(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getAnalysetableByProducts = (f, t, selected) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/sellers/products/table`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            from: f,
            to: t,
            sellers: selected
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setAnalysetableByProducts(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getAnalyseRoundBySales = (f, t, selected) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/sellers/sales/graph`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            from: f,
            to: t,
            sellers: selected
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setAnalyseRoundBySales(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getAnalysetableBySales = (f, t, selected) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/sellers/sales/table`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            from: f,
            to: t,
            sellers: selected
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setAnalysetableBySales(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export default sellersData.reducer