import { useNavigate } from "react-router-dom"

const TopBlock = ({title = "Page", back, backLink}) => {
    const navigate = useNavigate()

    return (
        <div>
            {back ? <div><span className="link" onClick={() => navigate(backLink)}>{back}</span></div> : null}
            <h1>{title}</h1>
        </div>
    )
}

export default TopBlock