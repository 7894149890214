import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import { createSeller, getSellers } from "../../../../store/SettingsStore/sellers"
import ModalFS from "../../../../components/Modal/fullScreen"
import { useNavigate } from "react-router-dom"

const SellersSettings = () => {

    const dispatch = useDispatch()

    const sellers = useSelector((state) => state.sellers.sellers)

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle('Продавцы'))
        dispatch(setPageTitleBack({name: 'Настройки', link: `/app/settings`}))
    }, [dispatch])

    useEffect(() => {
        dispatch(getSellers())
    }, [dispatch])

    const [c, setC] = useState(false)

    const createHandler = () => {
        setC(true)
    }

    const closeHandler = () => {
        setC(false)
    }

    return (
        <>
            <div>
                <button className="button mb-24" onClick={createHandler}>Создать</button>
            </div>
            <div>
                <table className="table mb-24">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Название</th>
                            <th>Контактное лицо</th>
                            <th>Телефон</th>
                            <th>Эл. почта</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sellers.map(e => <Row key={e.ID} data={e} />)}
                    </tbody>
                </table>
            </div>
            {c ? <ModalFS close={closeHandler} body={<CreateSeller />} /> : null }
        </>
    )
}

export default SellersSettings

const Row = ({data}) => {
    const navigate = useNavigate()
    return (
        <tr onClick={() => navigate(`${data.ID}`)}>
            <td>{data.ID}</td>
            <td>{data.name}</td>
            <td>{data.person}</td>
            <td>{data.phone}</td>
            <td>{data.email}</td>
        </tr>
    )
}

const CreateSeller = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [title, setTitle] = useState("");
    const [person, setPerson] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [comment, setComment] = useState("");

    const handlerSubmit = (e) => {
        e.preventDefault();
        dispatch(createSeller({name: title, person, phone, email, comment}, navigate))
    }

    return (
        <div style={{maxWidth: 500}}>
            <h3>Новый продавец</h3>
            <form onSubmit={handlerSubmit}>
                <div className="form-row">
                    <label htmlFor="name">
                            <div className="form-label">Название компании</div>
                            <input id="name"
                                name="name"
                                type="text"
                                required
                                className="text-field"
                                value={title}
                                onChange={e => setTitle(e.target.value)} />
                    </label>
                </div>
                <div className="form-row">
                    <label htmlFor="person">
                            <div className="form-label">Контактное лицо</div>
                            <input id="person"
                                name="person"
                                type="text"
                                className="text-field"
                                required
                                value={person}
                                onChange={e => setPerson(e.target.value)} />
                    </label>
                </div>
                <div className="form-row">
                    <label htmlFor="phone">
                            <div className="form-label">Телефон</div>
                            <input id="phone"
                                name="phone"
                                type="text"
                                className="text-field"
                                required
                                value={phone}
                                onChange={e => setPhone(e.target.value)} />
                    </label>
                </div>
                <div className="form-row">
                    <label htmlFor="email">
                            <div className="form-label">Эл. почта</div>
                            <input id="email"
                                name="email"
                                type="text"
                                className="text-field"
                                required
                                value={email}
                                onChange={e => setEmail(e.target.value)} />
                    </label>
                </div>
                <div className="form-row mb-24">
                    <label htmlFor="comment">
                        <div className="form-label">Комментарий</div>
                        <textarea id="comment"
                                name="comment"
                                type="text"
                                className="text-field"
                                value={comment}
                                onChange={e => setComment(e.target.value)} />
                    </label>
                </div>
                <div>
                    <button className="button">Создать</button>
                </div>
            </form>
        </div>
    )
}