import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
axios.defaults.withCredentials = true;

export const sellerData = createSlice({
    name: 'sellerData',
    initialState: {
        sellerAGraphicPeriod: "week",
        sellerAGraphic: null,
        sellerARoundByProducts: null,
        sellerATableByProducts: null,
        sellerProductsWidget: null
    },
    reducers: {
        setSellerDataAGraph: (state, action) => {
            state.sellerAGraphic = action.payload
        },
        setSellerDataAGraphPeriod: (state, action) => {
            state.sellerAGraphicPeriod = action.payload
        },
        setARoundByProducts: (state, action) => {
            state.sellerARoundByProducts = action.payload
        },
        setAtableByProducts: (state, action) => {
            state.sellerATableByProducts = action.payload
        },
        setSellerProductsWidget: (state, action) => {
            state.sellerProductsWidget = action.payload
        }
    }
})

export const {
    setSellerDataAGraph, 
    setSellerDataAGraphPeriod, 
    setARoundByProducts, 
    setAtableByProducts,
    setSellerProductsWidget } = sellerData.actions

export const getSellerDataAGraph = (period, selected) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/sellers/graph`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
           "period" : period,
           "sellers" : selected
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setSellerDataAGraph(response.data.result))
        })
        .catch((error) => {
            dispatch(setSellerDataAGraph(null))
        });
}

export const getARoundByProducts = (f, t, selected) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/sellers/products/graph`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            from: f,
            to: t,
            sellers: selected
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setARoundByProducts(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getAtableByProducts = (f, t, selected) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/sellers/products/table`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            from: f,
            to: t,
            sellers: selected
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setAtableByProducts(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getSellerProductsWidget = (sellerID) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/sellers/products/widget`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            ID: sellerID
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setSellerProductsWidget(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}


export default sellerData.reducer