import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getSeller } from "../../../../store/SettingsStore/seller"
import Preloader from "../../../../components/Preloader"
import SellerCompany from "./SellerCompany"
import SellerNullProducts from "./SellerNullProducts"

const SellerCompanyPage = () => {

    const dispatch = useDispatch()

    const {id, cid} = useParams()

    const seller = useSelector((state) => state.seller.seller)

    useEffect(() => {
        dispatch(getSeller(id))
    },[dispatch, id])

    if (!seller) {
        return <Preloader />
    }
    
    if (cid !== "0") {
        return <SellerCompany />
    } else {
        return <SellerNullProducts />
    }
}

export default SellerCompanyPage