import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";

import annotationPlugin from 'chartjs-plugin-annotation';
import { useEffect, useState } from "react";
import Preloader from "../../Preloader";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin
);


const SalesGraph = ({data}) => {

    const [lineData, setLineData] = useState(null)
    const [optionsLine, setOptionsLine] = useState(null)
    const [min, setMin] = useState(null)
    const [max, setMax] = useState(null)

    useEffect(() => {
        if (data && data.data) {
            if (typeof data.data[0].min !== 'undefined') {
                setMin(data.data[0].min)
            } else {
                setMin(0)
            }
            
            if (typeof data.data[0].max !== 'undefined') {
                setMax(data.data[0].max)
            } else {
                setMax(0)
            }

            let dataLine = {
                labels : data.labels,
                datasets: [
                    {
                        label: data.data[0].label,
                        data: data.data[0].points,
                        fill: false,
                        cubicInterpolationMode: 'monotone',
                        borderColor: "rgb(190, 215, 48)",
                    },
                    {
                        label: "Среднее",
                        data: data.data[0].middle,
                        fill: false,
                        cubicInterpolationMode: 'monotone',
                        borderColor: "rgb(50, 215, 48)",
                        borderDash: [3, 3],
                    }
                ]
            }

            setLineData({data: dataLine})
        }
    }, [data])

    useEffect(() => {
        if (min !== null && max !== null) {
            const optionsLine = {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: "top",
                    },
                }
            }
             
            optionsLine.plugins.annotation = {
                annotations: {
                    min : {
                        type: 'line',
                        borderColor: '#de401f',
                        borderWidth: 1,
                        borderDash: [3, 3],
                        label: {
                            backgroundColor: 'white',
                            color: '#de401f',
                            content: `Минимум — ${min.toLocaleString('ru-RU')}`,
                            display: true,
                            position: 'start'
                        },
                        scaleID: 'y',
                        value: min
                    },
                    max : {
                        type: 'line',
                        borderColor: '#21a74e',
                        borderWidth: 1,
                        borderDash: [3, 3],
                        label: {
                            backgroundColor: 'white',
                            color: '#21a74e',
                            content: `Максимум — ${max.toLocaleString('ru-RU')}`,
                            display: true,
                            position: 'start'
                        },
                        scaleID: 'y',
                        value: max
                    }
                }
            }
            setOptionsLine(optionsLine)
        }

    }, [min, max])

    if (!lineData) {
        return <Preloader />
    }

    return (
        <>
            <div style={{height: 320}}>
                <Line style={{width: '100%'}} data={lineData.data} options={optionsLine} />
            </div>
        </>
    )
}

export default SalesGraph