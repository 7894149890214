import { useDispatch, useSelector } from "react-redux"
import OneSellerMainGraphic from "./OneSellerMainGraphic"
import { useEffect, useState } from "react"
import FromToSelector from "../../../../components/FromToSelector"
import { getARoundByProducts, getAtableByProducts, getSellerProductsWidget, setARoundByProducts, setAtableByProducts, setSellerProductsWidget } from "../../../../store/AppStore/sellerData"
import OneSellerRoundGraphic from "./OneSellerRoundGraphic"
import CounterWidget from "../../../../components/Counter"
import Assort from "../../../../assets/images/assort.svg"

const SellerAppPage = ({seller}) => { 
    
    const dispatch = useDispatch()

    const sellerProductsWidget = useSelector((state) => state.sellerData.sellerProductsWidget)

    const [f, setF] = useState("")
    const [t, setT] = useState("")
    
    const handlerUpdate = (f, t) => {
        setF(f)
        setT(t)
    }

    useEffect(() => {
        if (t === "") {
            let d = new Date()
            let fd = new Date()
            fd.setDate(d.getDate() - 15)
            setT(`${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`)
            setF(`${fd.getFullYear()}-${fd.getMonth() + 1}-${fd.getDate()}`)
        }
    }, [t])

    useEffect(() => {
        if (f !== "" && t !== "") {
            dispatch(getARoundByProducts(f, t, [seller.ID]))
            dispatch(getAtableByProducts(f, t, [seller.ID]))
        }
    }, [dispatch, f, t, seller])

    useEffect(() => {
        dispatch(getSellerProductsWidget(seller.ID))
    }, [seller.ID, dispatch])

    useEffect(() => {
        return () => {
            dispatch(setSellerProductsWidget(null))
            dispatch(setARoundByProducts(null))
            dispatch(setAtableByProducts(null))
        }
    }, [dispatch])

    return (
        <div>
            <h1>{seller.name}</h1>
            <div className="flex">
                <div style={{flexGrow: 1}}>
                    <OneSellerMainGraphic sellerID={seller.ID} />
                    <div>
                        <FromToSelector from={f} to={t} handler={handlerUpdate} />
                    </div>
                    <div>
                        <SellerStatByProduct />
                    </div>
                </div>
                {sellerProductsWidget ? 
                    <div style={{flex: "0 0 350px", marginLeft: 20}}>
                        <CounterWidget
                                title={"Ассортиментная представленность"}
                                color={"#f90"}
                                number={sellerProductsWidget.value ?? 0}
                                numberTitle="позиции"
                                percent={`${sellerProductsWidget.percent ?? 0} %`}
                                icon={Assort} />
                    </div> : null }
            </div>
        </div>
    )
}

export default SellerAppPage

const SellerStatByProduct = () => {
    const sellerATableByProducts = useSelector((state) => state.sellerData.sellerATableByProducts)

    if (!sellerATableByProducts || !sellerATableByProducts.rows) {
        return null
    }

    return (
        <div className="flex" style={{marginBottom: 24}}>
            <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, flexGrow: 1, height: 380, display: "flex", flexDirection: "column", justifyContent: "space-between", border: "1px solid #ccc"}}>
                <div style={{overflowY: "scroll", height: 340, position: 'relative'}}>
                    <table className="table-tab">
                        <thead>
                            <tr style={{position: "sticky", top: 0}}>
                                <th></th>
                                <th>Товар</th>
                                <th>Количество, шт.</th>
                                <th>Доля от заказов, %</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sellerATableByProducts.rows.map((e, i) => 
                                <tr key={i}>
                                    <td>{i+1}.</td>
                                    <td>{e.title}</td>
                                    <td>{e.count}</td>
                                    <td>{e.percent} %</td>
                                </tr>)}
                        </tbody>
                        <tfoot>
                            <tr style={{position: "sticky", bottom: 0, background: "#fff", fontWeight: 600}}>
                                <td style={{paddingTop: 16}}></td>
                                <td style={{paddingTop: 16}}>ИТОГО</td>
                                <td style={{paddingTop: 16}}>{sellerATableByProducts.result.count}</td>
                                <td style={{paddingTop: 16}}>{sellerATableByProducts.result.percent} %</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, marginLeft: 20, flex: "0 0 500px", height: 380, border: "1px solid #ccc"}}>
                    <h3 style={{marginTop: 0}}>Заказы</h3>
                    <div style={{height: 300}}>
                        <OneSellerRoundGraphic />
                    </div>
            </div>
        </div>
    )
}