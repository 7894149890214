import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { checkAuth } from "../../store/AuthStore/auth"
import Preloader from "../Preloader/index"
import LoginPage from "../../pages/Auth/Login/index"
import MainWrapper from "../Wrapper/Main/index"

const AuthComponent = ({children, header = true}) => {

    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth.auth)
    const check = useSelector((state) => state.auth.check)

    useEffect(() => {
        dispatch(checkAuth())
    }, [dispatch])

    if (!check) {
        return <Preloader />
    }

    if (!auth) {
        return (
            <>
                <LoginPage />
            </>
        )
    }

    if (header) {
        return (
            <>
                <MainWrapper>{children}</MainWrapper>
            </>
        )
    } else {
        return children
    }
}

export default AuthComponent