import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
axios.defaults.withCredentials = true;

export const productData = createSlice({
    name: 'productData',
    initialState: {
        analyseGraphicPeriod: "week",
        analyseGraphic: null,
        analyseRoundBySellers: null,
        analyseTableBySellers: null,
        productsWidget: null
    },
    reducers: {
        setProductDataAnalyseGraph: (state, action) => {
            state.analyseGraphic = action.payload
        },
        setProductDataAnalyseGraphPeriod: (state, action) => {
            state.analyseGraphicPeriod = action.payload
        },
        setAnalyseRoundBySellersOne: (state, action) => {
            state.analyseRoundBySellers = action.payload
        },
        setAnalysetableBySellerOnes: (state, action) => {
            state.analyseTableBySellers = action.payload
        },
        setProductWidget: (state, action) => {
            state.productsWidget = action.payload
        }
    }
})

export const { setProductDataAnalyseGraph,
    setProductDataAnalyseGraphPeriod,
    setAnalyseRoundBySellersOne,
    setAnalysetableBySellerOnes,
    setProductWidget} = productData.actions

export const getProductDataAnalyseGraph = (period, selected) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/products/graph`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
           "period" : period,
           "products" : [selected]
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setProductDataAnalyseGraph(response.data.result))
        })
        .catch((error) => {
            dispatch(setProductDataAnalyseGraph(null))
        });
}

export const getAnalyseRoundBySellersOne = (f, t, selected) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/products/sellers/graph`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            from: f,
            to: t,
            products: [selected]
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setAnalyseRoundBySellersOne(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getAnalysetableBySellerOnes = (f, t, selected) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/products/sellers/table`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            from: f,
            to: t,
            products: [selected]
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setAnalysetableBySellerOnes(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getProductWidget = (id) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/products/sellers/widget`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            ID: id
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setProductWidget(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export default productData.reducer