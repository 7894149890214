import { configureStore } from '@reduxjs/toolkit'

import auth from './AuthStore/auth'
import pageTitle from './NavStore/title'
import messages from './System/messages'
import profile from './SettingsStore/profile'
import products from './ProductsStore/products'
import sellers from './SettingsStore/sellers'
import seller from './SettingsStore/seller'
import seller_company from './SettingsStore/seller_company'
import alerts from './System/alerts'
import appData from './AppStore/appData'
import dumpingData from './AppStore/dumpingData'
import salesData from './AppStore/salesData'
import monitorData from './AppStore/monitorData'
import productsData from './AppStore/productsData'
import productData from './AppStore/productData'
import sellersData from './AppStore/sellersData'
import sellerData from './AppStore/sellerData'

export default configureStore({
	reducer: {
        auth,
        pageTitle,
        messages,
        profile,
        products,
        sellers,
        seller,
        seller_company,
        alerts,
        appData,
        dumpingData,
        salesData,
        monitorData,
        productsData,
        sellersData,
        sellerData,
        productData
    },
})