import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { getCount } from './appData';
axios.defaults.withCredentials = true;

const defaultFilters = {
    view: false,
    BSellerID: 0,
    BProductID: 0
}

export const dumpingData = createSlice({
    name: 'dumpingData',
    initialState: {
        dumpingFilters : defaultFilters,
        dumpingReqFilters : null,
        dumpingList: null,
    },
    reducers: {
        setDumpingList: (state, action) => {
            state.dumpingList = action.payload
        },
        setDumpingFilters: (state, action) => {
            state.dumpingFilters = action.payload
        },
        setDumpingReqFilters: (state, action) => {
            state.dumpingReqFilters = action.payload
        }
    }
})

export const { setDumpingList, setDumpingFilters, setDumpingReqFilters } = dumpingData.actions

export const getDumpingList = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/dumping`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result.list) {
                dispatch(setDumpingList(response.data.result.list))
                dispatch(setDumpingReqFilters(response.data.result.request))
            } else {
                dispatch(setDumpingList([]))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setDumpingList([]))
        });
}

export const putDumpingItem = (put, data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'put',
        url: `${gatewayURL}/api/v1/b/dumping`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: put
    };

    axios.request(config)
        .then((response) => {
            dispatch(getDumpingList(data))
            dispatch(getCount("dumping"))
        })
        .catch((error) => {
            console.log(error);
        });
}

export default dumpingData.reducer