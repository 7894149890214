import "./style.css"

const CounterWidget = ({title, icon, color, number, numberTitle, percent}) => {
    return(
       <div className="counter-widget">
            <div className="counter-widget-inner">
                <h3>{title}</h3>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                    <div>
                        <span className="numberN">{number}</span>
                        <span className="numberT">{numberTitle}</span>
                    </div>
                    {percent > 0 ? 
                    <div style={{fontSize: 24, fontWeight: 600, color: color}}>
                        {percent}
                    </div> : null }
                </div>
            </div>
           <div className="counter-widget-icon" style={{backgroundColor: color}}>
                <img src={icon} alt={title}/>
           </div>
           <div className="counter-widget-before" style={{backgroundColor: color}}></div>
       </div>
   )
}

export default CounterWidget