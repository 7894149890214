import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
axios.defaults.withCredentials = true;

export const salesData = createSlice({
    name: 'salesData',
    initialState: {
        graph : null,
    },
    reducers: {
        setSalesGraph: (state, action) => {
            state.graph = action.payload
        }
    }
})

export const { setSalesGraph } = salesData.actions

export const getSalesGraphData = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/sales/graph`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            dispatch(setSalesGraph(response.data))
        })
        .catch((error) => {
            console.log(error);
        });
}

export default salesData.reducer