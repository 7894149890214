import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import { useParams } from "react-router-dom"
import { getCompanyProduct, getCompanyProducts, getSellerCompany, linkCompanyProduct, setCompanyProduct } from "../../../../store/SettingsStore/seller_company"
import Preloader from "../../../../components/Preloader"
import { getSeller } from "../../../../store/SettingsStore/seller"
import { gatewayURL } from "../../../../store/api"
import { getProducts } from "../../../../store/ProductsStore/products"

const SellerCompanyProduct = () => {
    const dispatch = useDispatch()

    const {id, cid, pid} = useParams()

    const seller = useSelector((state) => state.seller.seller)
    const company = useSelector((state) => state.seller_company.company)
    const companyProduct = useSelector((state) => state.seller_company.companyProduct)

    useEffect(() => {
        dispatch(getSeller(id))
        dispatch(getSellerCompany(id, cid))
        dispatch(getCompanyProducts(id, cid))
        dispatch(getCompanyProduct(id, cid, pid))
    }, [dispatch, id, cid, pid])

    useEffect (() => {
        return () => {
            dispatch(setCompanyProduct(null))
        }
    }, [dispatch])

    if (!seller || !company || !companyProduct) {
        return <Preloader />
    }

    return (
        <>
            <TopBlock />
            <ProductBlock />
        </>
    )
}

export default SellerCompanyProduct

const TopBlock = () => {

    const dispatch = useDispatch()

    const seller = useSelector((state) => state.seller.seller)
    const company = useSelector((state) => state.seller_company.company)
    const companyProduct = useSelector((state) => state.seller_company.companyProduct)

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle(company.company.name))
        dispatch(setPageTitleBack({name: company.company.name, link: `/app/settings/sellers/${seller.ID}/c/${company.company.ID}`}))
    }, [dispatch, seller, company])

    return (
        <>
            <h2>{companyProduct.product.title}</h2>
        </>
    )
}

const ProductBlock = () => {
    const companyProduct = useSelector((state) => state.seller_company.companyProduct)

    const [image, setImage] = useState(null)

    useEffect(() => {
        if (companyProduct.product.preview) {
            let imgData = companyProduct.product.preview.split("/")
            setImage(`${gatewayURL}/api/v1/images/${imgData[0]}/${imgData[2]}/${imgData[3]}`)
        }
    }, [companyProduct.product.preview])

    return (
        <div className="flex">
            <div style={{flex: '0 0 45%', marginRight: 20}}>
                <img style={{maxWidth: 250}} src={image} alt={companyProduct.product.title} />

                <h4>Ссылки на маркетплейсы</h4>

                {companyProduct.urls && companyProduct.urls.length > 0 ? <URLsBlock urls={companyProduct.urls} /> : "Нет ссылок"}
            </div>
            <div style={{flex: '0 0 50%'}}>
                <ProductLinksBlock linkID={companyProduct.ID ?? 0} BproductID={companyProduct.b_product.ID ?? 0} />
            </div>
        </div>
    )
}

const URLsBlock = ({urls}) => {
    return (
        <div>
            {urls.map((e, i) => <div key={i}><h4 >{e.shop}</h4><URLs urls={e.urls} /></div>)}
        </div>
    )
}

const URLs = ({urls}) => {
    return(
       <div>
            <ul>
                {urls.map((e, i) => <li key={i}><a target="_blank" rel="noreferrer" href={e.url}>{e.url}</a></li>)}
            </ul>
       </div>
   )
}

const ProductLinksBlock = ({linkID, BproductID}) => {

    const {id, cid, pid} = useParams()

    const dispatch = useDispatch()

    const products = useSelector((state) => state.products.products)

    const [pds, setPds] = useState([])

    if (!products || products.length === 0) {
        dispatch(getProducts())
    }

    useEffect(() => {
        let tmp = []
        tmp.push(
            {
                "ID": 0,
                "title": "Нет связки",
            }
        )
        if (products && products.length > 0) {
            products.forEach(element => {
                tmp.push(element)
            });
        }
        setPds(tmp)
    }, [products])

    if (pds.length === 0) {
        return <Preloader />
    }

    const handler = (value) => {
        dispatch(linkCompanyProduct(id, cid, pid, {do: "link", ID: linkID, b_productID: parseInt(value)}))
    }

    return (
        <>
            <h4>Связь с продуктами</h4>
            {pds.map((e, i) => <div key={i}>
                                        <input type="radio"
                                                id={`p_${e.ID}`} 
                                                name="link" 
                                                value={`${e.ID}`}
                                                onChange={(el) => handler(el.target.value)} 
                                                checked={e.ID === BproductID} />
                                            <label htmlFor={`p_${e.ID}`}>{e.title}</label>
                                        </div>)}
        </>
    )
}