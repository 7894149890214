import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { updateMessagesList } from '../System/messages';
axios.defaults.withCredentials = true;

export const sellers = createSlice({
    name: 'sellers',
    initialState: {
        sellers: []
    },
    reducers: {
        setSellers: (state, action) => {
            state.sellers = action.payload
        }
    }
})

export const { setSellers } = sellers.actions

export const getSellers = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/sellers`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result.b_sellers) {
                dispatch(setSellers(response.data.result.b_sellers))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const createSeller = (data, navigate) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/sellers`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                navigate(`${response.data.result.ID}`)
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export default sellers.reducer