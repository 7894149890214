import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { gatewayURL } from '../api';
axios.defaults.withCredentials = true;

export const alerts = createSlice({
    name: 'alerts',
    initialState: {
        list: []
    },
    reducers: {
        setAlertsList: (state, action) => {
            state.list = action.payload
        }
    }
})

export const { setAlertsList } = alerts.actions

export const getAlerts = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/alerts`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result.alerts) {
                dispatch(setAlertsList(response.data.result.alerts))
            } else {
                dispatch(setAlertsList([]))
            }
        })
        .catch((error) => {
            dispatch(setAlertsList([]))
            console.log(error);
        });
}

export default alerts.reducer