import { useEffect, useState } from "react"
import SalesGraph from "../../../../components/Graph/SalesGraph"
import PeriodSelector from "../../../../components/PeriodSelector"
import TopBlock from "../../../../components/TopBlock"

const SalesPage = () => {

    const [period, setPeriod] = useState("w")

    useEffect(() => {
        let now = new Date()

        switch (period) {
            case "d":
                now.setDate(now.getDate() - 1)
                break
            case "w":
                now.setDate(now.getDate() - 7)
                break
            case "m":
                now.setMonth(now.getMonth() - 1)
                break
            case "q":
                now.setMonth(now.getMonth() - 3)
                break
            case "y":
                now.setFullYear(now.getFullYear() - 1)
                break
            default:
                break
        }
    }, [period])

    return (
        <>
            <TopBlock title="Продажи" back="Главная" backLink={"/app"} />
            <PeriodSelector period={period} handler={setPeriod} />
            <SalesGraph />
        </>
    )
}

export default SalesPage

