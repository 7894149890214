import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
axios.defaults.withCredentials = true;

export const appData = createSlice({
    name: 'appData',
    initialState: {
        productsCount: 0,
        sellersCount: 0,
        dumpingCount: {forCompanies:0, nullCompanies:0},
        salesCount: {count: 0, from: "2024-05-01"},
        period: "week",
        graphCount: null,
        graphSum: null,
        pieCount: null,
        pieSum: null,
    },
    reducers: {
        setProductCount: (state, action) => {
            state.productsCount = action.payload
        },
        setSellerCount: (state, action) => {
            state.sellersCount = action.payload
        },
        setDumpingCount: (state, action) => {
            state.dumpingCount = action.payload
        },
        setSalesCount: (state, action) => {
            state.salesCount = action.payload
        },
        setAppPeriod: (state, action) => {
            state.period = action.payload
        },
        setAppGraphCount: (state, action) => {
            state.graphCount = action.payload
        },
        setAppGraphSum: (state, action) => {
            state.graphSum = action.payload
        },
        setAppPieCount: (state, action) => {
            state.pieCount = action.payload
        },
        setAppPieSum: (state, action) => {
            state.pieSum = action.payload
        }
    }
})

export const { setProductCount, setSellerCount, setDumpingCount, setSalesCount, setAppPeriod, setAppGraphCount, setAppGraphSum, setAppPieCount, setAppPieSum } = appData.actions

export const getCount = (type) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/${type}/count`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                switch (type) {
                    case 'products':
                        dispatch(setProductCount(response.data.result.count))
                        break
                    case 'sellers':
                        dispatch(setSellerCount(response.data.result.count))
                        break
                    case 'dumping':
                        dispatch(setDumpingCount(response.data.result))
                        break
                    default:
                        console.log(response.data.result)
                        break
                }
            }
        })
        .catch((error) => {
            console.log(error);
            switch (type) {
                case 'products':
                    dispatch(setProductCount(0))
                    break
                case 'sellers':
                    dispatch(setSellerCount(0))
                    break
                case 'dumping':
                    dispatch(setDumpingCount({forCompanies:0, nullCompanies:0}))
                    break
                default:
                    console.log(error)
                    break
            }
        });
}

export const getSalesCount = (from) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/sales/count?from=${from}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setSalesCount(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setSalesCount({count: 0, from: "2024-05-01"}))
        });
}

export const getAppGraphCount = (period) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/graph/main/count?period=${period}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setAppGraphCount(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setAppGraphCount(null))
        });
}

export const getAppGraphSum = (period) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/graph/main/sum?period=${period}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setAppGraphSum(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setAppGraphSum(null))
        });
}

export const getAppPieCount = (period) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/graph/pie/count?period=${period}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setAppPieCount(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setAppPieCount(null))
        });
}

export const getAppPieSum = (period) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/graph/pie/sum?period=${period}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setAppPieSum(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setAppPieSum(null))
        });
}

export default appData.reducer