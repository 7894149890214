import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import { getSellerUnlinkedProducts, setSellerUnlinkedProduct, setunlinkedProducts } from "../../../../store/SettingsStore/seller"
import { useNavigate, useParams } from "react-router-dom"
import ModalFS from "../../../../components/Modal/fullScreen"
import { getProducts } from "../../../../store/ProductsStore/products"
import AlertIcon from "../../../../assets/images/attention-svgrepo-com.svg"

const SellerNullProducts = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setunlinkedProducts([]))
    }, [dispatch])

    return (
        <>
            <TopBlock />
            <Products />
        </>
    )
}

const TopBlock = () => {

    const dispatch = useDispatch()

    const seller = useSelector((state) => state.seller.seller)

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle(seller.name))
        dispatch(setPageTitleBack({name: seller.name, link: `/app/settings/sellers/${seller.ID}`}))
    }, [dispatch, seller])

    return (
        <>
            <h2>Найденные продукты</h2>
        </>
    )
}

const Products = () => {
    const {id} = useParams()

    const dispatch = useDispatch()

    const unlinkedProducts = useSelector((state) => state.seller.unlinkedProducts)

    const [s, setS] = useState(false)

    const createHandler = () => {
        setS(true)
    }

    const closeHandler = () => {
        setS(false)
    }

    useEffect(() => {
        dispatch(getSellerUnlinkedProducts(id))
    }, [dispatch, id])

    return (
        <>
            <div>
                <table className="table mb-24">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Название</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {unlinkedProducts.map((e, i) => <RowList key={i} data={e} />)}
                    </tbody>
                </table>
            </div>
            <div>
                <button className="button mb-24" onClick={createHandler}>Добавить</button>
            </div>
            {s ? <ModalFS close={closeHandler} body={<CreateForm close={closeHandler} />} /> : null}
        </>
    )
}

export default SellerNullProducts

const RowList = ({data}) => {
    
    const navigate = useNavigate()

    const [a, setA] = useState(false)

    useEffect(() => {
        if (data.urls && data.urls.length > 0) {
            data.urls.forEach(url => {
                url.urls.forEach(u => {
                    if (u.alert) {
                        setA(true)
                    }
                })
            });
        }
    }, [data])

    return (
        <tr onClick={() => navigate(`${data.ID}`)}>
            <td>{data.b_product.ID}</td>
            <td>{data.b_product.title}</td>
            <td>{a ? <img src={AlertIcon} alt="alert" style={{width: 25, cursor: "pointer"}} /> : null}</td>
        </tr>
    )
}

const CreateForm = ({close}) => {

    const {id} = useParams()

    const dispatch = useDispatch()

    const products = useSelector((state) => state.products.products)
    const unlinkedProducts = useSelector((state) => state.seller.unlinkedProducts)

    const [pds, setPds] = useState([])

    if (!products || products.length === 0) {
        dispatch(getProducts())
    }

    useEffect(() => {
        let tmp = []
        if (products && products.length > 0) {
            products.forEach(BProduct => {
                let exp = false
                unlinkedProducts.forEach(ex => {
                    if (BProduct.ID === ex.b_product.ID) {
                        exp = true
                    }
                });

                if (!BProduct.active) {
                    exp = true
                }

                if (!exp) {
                    tmp.push(BProduct)
                }
            });
        }
        setPds(tmp)
    }, [products, unlinkedProducts])

    const addHandler = (pid) => {
        dispatch(setSellerUnlinkedProduct(id, {BProductID : parseInt(pid)}))
        close()
    }

    return(
       <>
           <h2>Добавить продукт</h2>
           <table className="table mb-24">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Название</th>
                    </tr>
                </thead>
                <tbody>
                    {pds.map((e, i) => <RowListP key={i} data={e} handler={addHandler} />)}
                </tbody>
            </table>
       </>
   )
}

const RowListP = ({data, handler}) => {
    return (
        <tr onClick={() => handler(`${data.ID}`)}>
            <td>{data.ID}</td>
            <td>{data.title}</td>
        </tr>
    )
}