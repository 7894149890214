const periods = [
    {
        id: "w",
        label: "Неделя"
    },
    {
        id: "m",
        label: "Месяц"
    },
    {
        id: "q",
        label: "Квартал"
    },
    {
        id: "y",
        label: "Год"
    },
]

const PeriodSelector = ({period, handler}) => {
    return (
        <div className="button-group">
            {periods.map(e => <div key={e.id}
                                    onClick={() => handler(e.id)}
                                    className={`button-group-item ${period === e.id}`}>
                                    {e.label}
                                </div>)}
        </div>
    )
}

export default PeriodSelector