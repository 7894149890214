import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import AuthComponent from "./components/Auth";
import ErrorPage from "./pages/Error";
import HomePage from "./pages/Web";
import { Provider } from "react-redux";
import store from "./store/store";
import LoginPage from "./pages/Auth/Login";
import AppPage from "./pages/App/AppPage";
import Messages from "./components/Messages";
import SettingsPage from "./pages/App/SettingsPage";
import MainSettings from "./pages/App/SettingsPage/MainSettings";
import ProfileSettings from "./pages/App/SettingsPage/ProfileSettings";
import ProductsSettings from "./pages/App/SettingsPage/ProductsSettings";
import SellersSettings from "./pages/App/SettingsPage/SellersSettings";
import ProductPage from "./pages/App/SettingsPage/ProductsSettings/ProductPage";
import SellerPage from "./pages/App/SettingsPage/SellersSettings/SellerPage";
import SellerCompanyPage from "./pages/App/SettingsPage/SellersSettings/SellerCompanyPage";
import SellerCompanyProductPage from "./pages/App/SettingsPage/SellersSettings/SellerCompanyProductPage";
import ProductsAppPage from "./pages/App/AppPage/ProductsPages/products";
import SellersAppPage from "./pages/App/AppPage/SellersPages/sellers";
import DumpingsAppPage from "./pages/App/AppPage/DumpingPages/dumpings";
import SalesPage from "./pages/App/AppPage/SalesPages";
import Monitor from "./pages/App/AppPage/Monitor";

const Layout = ({header}) => {
    return (
        <AuthComponent header={header}>
            <Outlet />
        </AuthComponent>
    )
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <HomePage />,
        errorElement: <ErrorPage />
    },
    {
        path: '/login',
        element: <LoginPage />,
        errorElement: <ErrorPage />
    },
    {
        path:'/monitor',
        element: <Layout header={false} />,
        errorElement: <ErrorPage />,
        children: [
            {
                path:':monitor',
                element: <Monitor />
            }
        ]
    },
    {
        path: '/app',
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path:'',
                element: <AppPage />
            },
            {
                path:'products',
                children: [
                    {
                        path:'',
                        element: <ProductsAppPage />
                    }
                ]
            },
            {
                path:'sellers',
                children: [
                    {
                        path:'',
                        element: <SellersAppPage />
                    }
                ]
            },
            {
                path:'dumping',
                children: [
                    {
                        path:'',
                        element: <DumpingsAppPage />
                    }
                ]
            },
            {
                path:'sales',
                children: [
                    {
                        path:'',
                        element: <SalesPage />
                    }
                ]
            },
            {
                path:'settings',
                element: <SettingsPage />,
                children: [
                    {
                        path:'',
                        element: <MainSettings />
                    },
                    {
                        path:'profile',
                        element: <ProfileSettings />
                    },
                    {
                        path:'sellers',
                        children: [
                            {
                                path:'',
                                element: <SellersSettings />
                            },
                            {
                                path:':id',
                                children: [
                                    {
                                        path: '',
                                        element: <SellerPage />
                                    },
                                    {
                                        path: 'c/:cid',
                                        element: <SellerCompanyPage />
                                    },
                                    {
                                        path: 'c/:cid/:pid',
                                        element: <SellerCompanyProductPage />
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        path:'products',
                        children: [
                            {
                                path:'',
                                element: <ProductsSettings />,
                            },
                            {
                                path:':id',
                                element: <ProductPage />
                            },
                        ]
                    },
                ]
            }
        ]
    }
])

function App() {
    return (
        <Provider store={store}>
            <RouterProvider router={router} />
            <Messages/>
        </Provider>
    );
}

export default App;
