import { useDispatch, useSelector } from "react-redux"
import TopBlock from "../../../../components/TopBlock"
import { useEffect, useState } from "react"
import { getAnalyseRoundByProducts, getAnalyseRoundBySales, getAnalysetableByProducts, getAnalysetableBySales, getSellersDataList, setSellersDataSelected } from "../../../../store/AppStore/sellersData"
import ModalFS from "../../../../components/Modal/fullScreen"
import SellerMainGraphic from "./SellerMainGraphic"
import FromToSelector from "../../../../components/FromToSelector"
import SellerRoundGraphic from "./SellerRoundGraphic"
import SellerRoundGraphicSales from "./SellerRoundGraphicSales"
import SellerAppPage from "./seller"

const SellersAppPage = () => {
    return (
        <>
            <TopBlock title="Продавцы" back="Главная" backLink={"/app"} />
            <div className="flex" style={{alignItems: "flex-start"}}>
                <SellersList />
                <SellersAnalyse />
            </div>

        </>
    )
}

export default SellersAppPage

const SellersList = () => {

    const dispatch = useDispatch()
    
    const list = useSelector((state) => state.sellersData.list)
    const selected = useSelector((state) => state.sellersData.selected)

    const [sl, setSL] = useState([])
    const [sa, setSA] = useState(false)
    const [os, setOS] = useState(null)

    useEffect(() => {
        if (!list) {
            dispatch(getSellersDataList())
        } else {
            if (list.length > 0) {
                if (selected.length === 0) {
                    let tmp = []
    
                    list.forEach((element, i) => {
                        if (i < 3) {
                            tmp.push(element.b_seller.ID)
                        }
                    });
    
                    dispatch(setSellersDataSelected(tmp))
                } else {
                    let tmp = []
    
                    list.forEach(element => {
                        let e = Object.assign({}, element)
                        if (selected.includes(element.b_seller.ID)) {
                            e.selected = true
                        }else {
                            e.selected = false
                        }
                        tmp.push(e)
                    });
        
                    setSL(tmp)
                }
            }
        }
    }, [dispatch, list, selected])

    const handlerCheck = (check, id) => {
        let tmp = [...selected]
        if (check) {
            tmp.push(id)
        } else {
            tmp = tmp.filter(el => el !== id)

            dispatch(setSellersDataSelected([]))
            setSA(false)

        }
        dispatch(setSellersDataSelected(tmp))
    }

    const handlerCheckAll = (check) => {
        
        let tmp = []

        if (check) {
            list.forEach(element => {
                tmp.push(element.b_seller.ID)
            });
        }

        dispatch(setSellersDataSelected(tmp))
        setSA(check)
    }

    const handlerOpenSeller = (id) => {
        setOS(id)
    }

    const handlerCloseSeller = () => {
        setOS(null)
    }

    return (
        <>
            <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, height: "70vh"}}>
                <div style={{height: "100%", overflowY: "auto"}}>
                    <table className="table-tab">
                        <thead>
                            <tr>
                                <th style={{width: 35}}>
                                    <input type="checkbox" checked={sa} onChange={el => handlerCheckAll(el.target.checked)} />
                                </th>
                                <th>Выбрать всех</th>
                                <th style={{textAlign: "right", width: 120}} width={120} align="right">Объем заказов, руб.</th>
                                <th style={{textAlign: "right", width: 120}} width={120} align="right">% от объема заказов</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sl.map((e, i) => 
                            <tr key={i}>
                                <td><input type="checkbox" checked={e.selected} onChange={el => handlerCheck(el.target.checked, e.b_seller.ID)} /></td>
                                <td><span className="link" onClick={() => handlerOpenSeller(e.b_seller)}>{e.b_seller.name}</span></td>
                                <td style={{textAlign: "right"}} align="right">{e.salesVolume.value ? e.salesVolume.value.toLocaleString("ru-RU") : 0}</td>
                                <td style={{textAlign: "right"}} align="right">{e.salesVolume.percent ? e.salesVolume.percent : 0} %</td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {os ? <ModalFS close={handlerCloseSeller} body={<SellerAppPage seller={os} />} /> : null}
        </>
    )
}

const SellersAnalyse = () => {

    const selected = useSelector((state) => state.sellersData.selected)

    const dispatch = useDispatch()
    const [f, setF] = useState("")
    const [t, setT] = useState("")
    
    const handlerUpdate = (f, t) => {
        setF(f)
        setT(t)
    }

    useEffect(() => {
        if (t === "") {
            let d = new Date()
            let fd = new Date()
            fd.setDate(d.getDate() - 15)
            setT(`${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`)
            setF(`${fd.getFullYear()}-${fd.getMonth() + 1}-${fd.getDate()}`)
        }
    }, [t])

    useEffect(() => {
        if (f !== "" && t !== "" && selected.length > 0) {
            dispatch(getAnalyseRoundByProducts(f, t, selected))
            dispatch(getAnalysetableByProducts(f, t, selected))
            dispatch(getAnalyseRoundBySales(f, t, selected))
            dispatch(getAnalysetableBySales(f, t, selected))
        }
    }, [dispatch, f, t, selected])

    return (
        <>
            <div style={{marginLeft: 20, flexGrow: 1}}>
                <h2 style={{marginTop: 0}}>Анализ выбранных продавцов</h2>
                
                <SellerMainGraphic />

                <div>
                    <FromToSelector from={f} to={t} handler={handlerUpdate} />
                </div>

                <div>
                    <SellerStatByProduct />
                    <SellerStatBySales />
                </div>
            </div>
        </>
    )
}

const SellerStatByProduct = () => {
    const analyseTableByProducts = useSelector((state) => state.sellersData.analyseTableByProducts)

    if (!analyseTableByProducts || !analyseTableByProducts.rows) {
        return null
    }

    return (
        <div className="flex" style={{marginBottom: 24}}>
            <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, flexGrow: 1, height: 380, display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                <div style={{overflowY: "scroll", height: 340, position: 'relative'}}>
                    <table className="table-tab">
                        <thead>
                            <tr style={{position: "sticky", top: 0}}>
                                <th></th>
                                <th>Товар</th>
                                <th>Количество, шт.</th>
                                <th>Доля от заказов, %</th>
                            </tr>
                        </thead>
                        <tbody>
                            {analyseTableByProducts.rows.map((e, i) => 
                                <tr key={i}>
                                    <td>{i+1}.</td>
                                    <td>{e.title}</td>
                                    <td>{e.count}</td>
                                    <td>{e.percent} %</td>
                                </tr>)}
                        </tbody>
                        <tfoot>
                            <tr style={{position: "sticky", bottom: 0, background: "#fff", fontWeight: 600}}>
                                <td style={{paddingTop: 16}}></td>
                                <td style={{paddingTop: 16}}>ИТОГО</td>
                                <td style={{paddingTop: 16}}>{analyseTableByProducts.result.count}</td>
                                <td style={{paddingTop: 16}}>{analyseTableByProducts.result.percent} %</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, marginLeft: 20, flex: "0 0 500px", height: 380}}>
                    <h3 style={{marginTop: 0}}>Заказы</h3>
                    <div style={{height: 300}}>
                        <SellerRoundGraphic />
                    </div>
            </div>
        </div>
    )
}

const SellerStatBySales = () => {
    const analyseTableBySales = useSelector((state) => state.sellersData.analyseTableBySales)

    if (!analyseTableBySales || !analyseTableBySales.rows) {
        return null
    }

    return (
        <div className="flex" style={{marginBottom: 24}}>
            <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, flexGrow: 1, height: 380, display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                <div style={{overflowY: "scroll", height: 340, position: 'relative'}}>
                    <table className="table-tab">
                        <thead>
                            <tr style={{position: "sticky", top: 0}}>
                                <th></th>
                                <th>Товар</th>
                                <th>Количество, шт.</th>
                                <th>Доля от заказов, %</th>
                                <th>Объем заказов, руб.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {analyseTableBySales.rows.map((e, i) => 
                                <tr key={i}>
                                    <td>{i+1}.</td>
                                    <td>{e.title}</td>
                                    <td>{e.count}</td>
                                    <td>{e.percent} %</td>
                                    <td>{e.sum.toLocaleString("ru-RU")}</td>
                                </tr>)}
                        </tbody>
                        <tfoot>
                            <tr style={{position: "sticky", bottom: 0, background: "#fff", fontWeight: 600}}>
                                <td style={{paddingTop: 16}}></td>
                                <td style={{paddingTop: 16}}>ИТОГО</td>
                                <td style={{paddingTop: 16}}>{analyseTableBySales.result.count}</td>
                                <td style={{paddingTop: 16}}>{analyseTableBySales.result.percent} %</td>
                                <td style={{paddingTop: 16}}>{analyseTableBySales.result.sum.toLocaleString("ru-RU")}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, marginLeft: 20, flex: "0 0 500px", height: 380}}>
                    <h3 style={{marginTop: 0}}>Заказы</h3>
                    <div style={{height: 300}}>
                        <SellerRoundGraphicSales />
                    </div>
            </div>
        </div>
    )
}