import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import { useNavigate, useParams } from "react-router-dom"
import { deleteSellerUnlinkedProduct, deleteSellerUnlinkedProductURL, getSeller, getSellerUnlinkedProduct, setSellerUnlinkedProductURL, setunlinkedProduct } from "../../../../store/SettingsStore/seller"
import Preloader from "../../../../components/Preloader"
import { gatewayURL } from "../../../../store/api"
import ButtonDelete from "../../../../components/Buttons/button-delete"
import ModalFS from "../../../../components/Modal/fullScreen"
import Logo from "../../../../assets/images/logo.svg"
import Modal from "../../../../components/Modal"
import Confirm from "../../../../components/Confirm"
import AlertIcon from "../../../../assets/images/attention-svgrepo-com.svg"
import { getAlertProduct, setAlertProduct } from "../../../../store/SettingsStore/seller_company"

const shopList = [
    {
        shopCode: "WB",
        title: "Wildberries"
    },
    {
        shopCode: "Ozon",
        title: "Ozon"
    },
    {
        shopCode: "YM",
        title: "Yandex Market"
    }
]

const SellerNullProduct = () => {

    const {id, pid} = useParams()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getSeller(id))
        dispatch(getSellerUnlinkedProduct(id, pid))
    }, [dispatch, id, pid])

    useEffect(() => {
        return () => {
            dispatch(setunlinkedProduct(null))
            dispatch(setAlertProduct(null))
        }
    }, [dispatch])

    const seller = useSelector((state) => state.seller.seller)
    const unlinkedProduct = useSelector((state) => state.seller.unlinkedProduct)

    if (!seller || !unlinkedProduct) {
        return <Preloader />
    }

    return (
        <>
            <TopBlock />
            <ProductData />
        </>
    )
}

const TopBlock = () => {

    const dispatch = useDispatch()

    const seller = useSelector((state) => state.seller.seller)
    const unlinkedProduct = useSelector((state) => state.seller.unlinkedProduct)

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle(seller.name))
        dispatch(setPageTitleBack({name: "Назад", link: `/app/settings/sellers/${seller.ID}/c/0`}))
    }, [dispatch, seller])

    return (
        <>
            <h2>{unlinkedProduct.b_product.title}</h2>
        </>
    )
}

const ProductData = () => {
    const {id, pid} = useParams()

    const unlinkedProduct = useSelector((state) => state.seller.unlinkedProduct)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [image, setImage] = useState(null)
    const [s, setS] = useState(false)
    const [m, setM] = useState(false)

    const createHandler = () => {
        setS(true)
    }

    const closeHandler = () => {
        setS(false)
    }

    const closeModalHandler = () => {
        setM(false)
    }

    const deleteHandler = () => {
        setM(true)
    }

    const confirmDelete = () => {
        setM(false)
        dispatch(deleteSellerUnlinkedProduct(id, pid, navigate))
    }

    useEffect(() => {
        if (unlinkedProduct.b_product.preview) {
            setImage(`${gatewayURL}/api/v1/b/images/${unlinkedProduct.b_product.ID}/${unlinkedProduct.b_product.preview}`)
        } else {
            setImage(Logo)
        }
    }, [unlinkedProduct.b_product])

    return (
        <>
            <div className="flex">
                <div style={{flex: '0 0 270', marginRight: 20}}>
                    <img style={{width: 250, maxWidth: 250, marginBottom: 24}} src={image} alt={unlinkedProduct.b_product.title} />
                    {!unlinkedProduct.urls || unlinkedProduct.urls.length === 0 ?
                        <div>
                            <button className="button mb-24" style={{backgroundColor: '#a01a42'}} onClick={deleteHandler}>Удалить</button>
                        </div> : null }
                </div>
                <div style={{flexGrow: 1}}>
                    <h4>Ссылки на маркетплейсы</h4>
                    {unlinkedProduct.urls && unlinkedProduct.urls.length > 0 ? <URLsBlock urls={unlinkedProduct.urls} /> : <div className="mb-24">Нет ссылок</div>}
                    <div>
                        <button className="button mb-24" onClick={createHandler}>Добавить</button>
                    </div>
                </div>
            </div>
            {s ? <ModalFS close={closeHandler} body={<CreateForm />} /> : null}
            {m ? <Modal close={closeModalHandler} body={<Confirm callbackNo={closeModalHandler} callbackYes={confirmDelete} />} /> : null}
        </>
    )
}

export default SellerNullProduct

const URLsBlock = ({urls}) => {
    return (
        <>
            <div>
                {urls.map((e, i) => <div key={i}>
                                        <URLsShop shopUrls={e} />
                                    </div>)}
            </div>
        </>
    )
}

const URLsShop = ({shopUrls}) => {
    const [s, setS] = useState(null)

    useEffect(() => {
        shopList.forEach(element => {
            if (element.shopCode === shopUrls.shop) {
                setS(element)
            }
        });
    }, [shopUrls])

    if (!s) {
        return null
    }

    return (
        <div>
            <h3>{s.title}</h3>
            <URLs urls={shopUrls.urls} />
        </div>
    )
}

const URLs = ({urls}) => {
    return(
        <>
            <div>
                <table className="table mb-24">
                    <thead>
                        <tr>
                            <th>URL</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {urls.map((e, i) => <RowURL key={i} data={e} />)}
                    </tbody>
                </table>
            </div>
        </>
   )
}

const RowURL = ({data}) => {
    const {id, pid} = useParams()

    const dispatch = useDispatch()
    
    const handlerDelete = () => {
        dispatch(deleteSellerUnlinkedProductURL(id, pid, {ID: data.ID}))
    }

    return (
        <tr style={{cursor: "default"}}>
            <td width={'50%'}><a target="_blank" rel="noreferrer" href={data.url}>{data.url.substr(0, 80)}...</a></td>
            <td>{data.alert ? <Alert alert={data.alert} /> : null}</td>
            <td><ButtonDelete handler={handlerDelete} /></td>
        </tr>
    )
}

const Alert = ({alert}) => {
    const [s, setS] = useState(false)

    const openHandler = () => {
        setS(true)
    }

    const closeHandler = () => {
        setS(false)
    }

    return (
        <>
            <div>
                <img onClick={openHandler} src={AlertIcon} alt="alert" style={{width: 30, cursor: "pointer"}} />
            </div>
            {s ? <Modal close={closeHandler} body={<AlertBody alert={alert} />} /> : null}
        </>
    )
}

const CreateForm = () => {

    const {id, pid} = useParams()

    const unlinkedProduct = useSelector((state) => state.seller.unlinkedProduct)

    const dispatch = useDispatch()
    
    const [u, setU] = useState("")
    const [m, setM] = useState("")

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(setSellerUnlinkedProductURL(id, pid, {
            nullProductID: unlinkedProduct.ID,
            shopCode: m,
            url: u
        }))
    }

    return (
        <div>
            <form onSubmit={submitHandler}>
                <div className="form-row">
                    <label htmlFor="mp">
                        <div className="form-label">Маркетплейс</div>
                        <select id="mp" className="select-field" onChange={e => setM(e.target.value)} required>
                            <option value="">Выбрать</option>
                            {shopList.map((e, i) => <option key={i} value={e.shopCode}>{e.title}</option>)}
                        </select>
                    </label>
                </div>
                <div className="form-row mb-24">
                    <label htmlFor="name">
                            <div className="form-label">URL</div>
                            <input id="name"
                                name="name"
                                type="text"
                                required
                                className="text-field"
                                value={u}
                                onChange={e => setU(e.target.value)} />
                    </label>
                </div>
                <div>
                    <button className="button">Добавить</button>
                </div>
            </form>
        </div>
    )
}

const AlertBody = ({alert}) => {

    const alertProduct = useSelector((state) => state.seller_company.alertProduct)
    
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAlertProduct(alert.seller.ID, alert.sellerCompany.company.ID, alert.sellerCompanyProduct.product.ID))
    }, [dispatch, alert])

    if (!alertProduct) {
        return <Preloader />
    }

    return (
        <>
            <h2>Обратите внимание!</h2>
            <div>{alert.alert}</div>
            <h3>В товаре продавца - {alertProduct.product.title}</h3>
            <ProductBlock />
        </>
    )
}

const ProductBlock = () => {
    const alertProduct = useSelector((state) => state.seller_company.alertProduct)

    const [image, setImage] = useState(null)

    useEffect(() => {
        if (alertProduct.product.preview) {
            let imgData = alertProduct.product.preview.split("/")
            setImage(`${gatewayURL}/api/v1/images/${imgData[0]}/${imgData[2]}/${imgData[3]}`)
        }
    }, [alertProduct.product.preview])

    return (
        <div className="flex">
            <div style={{flex: '0 0 45%', marginRight: 20}}>
                <img style={{maxWidth: 250}} src={image} alt={alertProduct.product.title} />

                <h4>Ссылки на маркетплейсы</h4>

                {alertProduct.urls && alertProduct.urls.length > 0 ? <URLsBlockA urls={alertProduct.urls} /> : "Нет ссылок"}
            </div>
        </div>
    )
}

const URLsBlockA = ({urls}) => {
    return (
        <div>
            {urls.map((e, i) => <div key={i}><h4 >{e.shop}</h4><URLsA urls={e.urls} /></div>)}
        </div>
    )
}

const URLsA = ({urls}) => {
    return(
       <div>
            <ul>
                {urls.map((e, i) => <li key={i}><a target="_blank" rel="noreferrer" href={e.url}>{e.url}</a></li>)}
            </ul>
       </div>
   )
}