import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

ChartJS.register(ArcElement, Tooltip, Legend);

const OneSellerRoundGraphic = () => {

    const data = useSelector((state) => state.productData.analyseRoundBySellers)
    
    if (!data || !data.labels) {
        return null
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "right"
            },
        }
      }

    return (
        <Doughnut data={data} options={options} />
    )
}

export default OneSellerRoundGraphic