import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { updateMessagesList } from '../System/messages';
axios.defaults.withCredentials = true;

export const seller = createSlice({
    name: 'seller',
    initialState: {
        seller: null,
        companies: [],
        unlinkedProducts: [],
        unlinkedProduct: null
    },
    reducers: {
        setSeller: (state, action) => {
            state.seller = action.payload
        }, 
        setSellerCompanies: (state, action) => {
            state.companies = action.payload
        }, 
        setunlinkedProducts: (state, action) => {
            state.unlinkedProducts = action.payload
        },
        setunlinkedProduct: (state, action) => {
            state.unlinkedProduct = action.payload
        }
    }
})

export const { setSeller, setSellerCompanies, setunlinkedProducts, setunlinkedProduct } = seller.actions

export const getSeller = (id) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/sellers/${id}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setSeller(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const updateSeller = (id, data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'put',
        url: `${gatewayURL}/api/v1/b/sellers/${id}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(getSeller(id))
                dispatch(updateMessagesList(
                    {
                        message: "ok",
                        type: "ok",
                        time: t.getTime()
                    }
                ))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const getSellerCompanies = (id) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/sellers/${id}/companies`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result.data) {
                dispatch(setSellerCompanies(response.data.result.data))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setSellerCompanies([]))
        });
}

export const getSellerUnlinkedProducts = (id) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/sellers/${id}/companies/0/products`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result.seller_company_products) {
                dispatch(setunlinkedProducts(response.data.result.seller_company_products))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setunlinkedProducts([]))
        });
}

export const getSellerUnlinkedProduct = (id, pid) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/sellers/${id}/companies/0/products/${pid}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setunlinkedProduct(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setunlinkedProduct(null))
        });
}

export const setSellerUnlinkedProduct = (id, data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/sellers/${id}/companies/0/products`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getSellerUnlinkedProducts(id))
            dispatch(updateMessagesList(
                {
                    message: "ok",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const setSellerUnlinkedProductURL = (id, pid, data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/sellers/${id}/companies/0/products/${pid}/link`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getSellerUnlinkedProduct(id, pid))
            dispatch(updateMessagesList(
                {
                    message: "ok",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const deleteSellerUnlinkedProductURL = (id, pid, data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'delete',
        url: `${gatewayURL}/api/v1/b/sellers/${id}/companies/0/products/${pid}/link`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getSellerUnlinkedProduct(id, pid))
            dispatch(getSellerUnlinkedProducts(id))
            dispatch(updateMessagesList(
                {
                    message: "ok",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const deleteSellerUnlinkedProduct = (id, pid, navigate) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'delete',
        url: `${gatewayURL}/api/v1/b/sellers/${id}/companies/0/products/${pid}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(updateMessagesList(
                {
                    message: "ok",
                    type: "ok",
                    time: t.getTime()
                }
            ))
            navigate(`/app/settings/sellers/${id}/c/0`)
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export default seller.reducer