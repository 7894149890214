import { useDispatch, useSelector } from "react-redux"
import IconSVG from "../../../IconSVG"
import { useEffect, useState } from "react"
import { getAlerts } from "../../../../store/System/alerts"
import Modal from "../../../Modal"
import "./style.css"
import { useNavigate } from "react-router-dom"

const Alerts = () => {

    const dispatch = useDispatch()

    const alerts = useSelector((state) => state.alerts.list)

    const [s, setS] = useState(false)

    useEffect(() => {
        dispatch(getAlerts())
    }, [dispatch])

    const openHandler = () => {
        setS(true)
    }

    const closeHandler = () => {
        setS(false)
    }

    if (alerts.length === 0) {
        return (
            <IconSVG icon="Alert" color="#ccc" />
        )
    }

    return (
        <>
            <div style={{cursor: "pointer"}} onClick={openHandler}>
                <IconSVG icon="Alert" color="#DE2E62" />
            </div>
            {s ? <Modal close={closeHandler} body={<AlertList close={closeHandler} />} /> : null}
        </>
    )
}

export default Alerts

const AlertList = ({close}) => {
    const alerts = useSelector((state) => state.alerts.list)

    const navigate = useNavigate()

    const handlerOpen = (alert) => {
        close()

        if (alert.type === "seller_null_product_url") {
            navigate(`/app/settings/sellers/1/c/0/${alert.sellerCompanyProductID}`)
        }
    }

    return (
        <div style={{maxWidth: 650}}>
            <h2>Обратите внимание!</h2>
            <table className="table">
                <tbody>
                    {alerts.map((alert, i) => <tr key={i} onClick={() => handlerOpen(alert)}>
                        <td valign="top">Ссылки</td>
                        <td>{alert.message}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}