import TopBlock from "../../../../components/TopBlock"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { getCount } from "../../../../store/AppStore/appData"
import { getDumpingList, putDumpingItem } from "../../../../store/AppStore/dumpingData"
import DumpingFilters from "./dumpingFilters"
import ModalFS from "../../../../components/Modal/fullScreen"
import { gatewayURL } from "../../../../store/api"

const menu = [
    {
        label: 'Компании',
        value: 'forCompanies',
        active: false,
        count: 0
    },
    {
        label: 'Найденные продукты',
        value: 'nullCompanies',
        active: false,
        count: 0
    }
]

const DumpingsAppPage = () => {
    const dispatch = useDispatch()
    const dumpingCount = useSelector((state) => state.appData.dumpingCount)

    const [m, setM] = useState([])
    const [s, setS] = useState("forCompanies")

    useEffect(() => {
        dispatch(getCount("dumping"))
    }, [dispatch])

    useEffect(() => {
        let tmp = []
        menu.forEach(element => {
            element.active = false
            if (element.value === s) {
                element.active = true
            }
            element.count = dumpingCount[element.value]
            tmp.push(element)
        });

        setM(tmp)
    }, [dumpingCount, s])

    return (
        <>
            <TopBlock title="Демпинг" back="Главная" backLink={"/app"} />
            <div className="mb-24">
                <ul className="line-menu">
                    {m.map((item) => <li key={item.value} className={item.active ? "active" : ""} onClick={() => setS(item.value)}>{item.label} - {item.count}</li>)}
                </ul>
            </div>
            <DumpingList s={s} />
        </>
    )
}

export default DumpingsAppPage

const DumpingList = ({s}) => {
    const dispatch = useDispatch()

    const [m, setM] = useState(false)
    const [d, setD] = useState(null)

    const dumpingFilters = useSelector((state) => state.dumpingData.dumpingFilters)
    const dumpingList = useSelector((state) => state.dumpingData.dumpingList)

    useEffect(() => {
        let f = {...dumpingFilters}
        f.dumpingType = s
        dispatch(getDumpingList(f))
    }, [dispatch, dumpingFilters, s])

    const openHandler = (data) => {
        setM(true)
        setD(data)
    }

    const closeHandler = () => {
        setM(false)
        setD(null)
    }

    if (!dumpingList) return null

    return (
        <>
            <div className="flex" style={{backgroundColor: "#fff", padding: 20, borderRadius: 12}}>
                <div style={{flex: "0 0 250px"}}>
                    <DumpingFilters />
                </div>
                <div style={{flexGrow: 1}}>
                    <table className="table mb-24">
                        <thead>
                            <tr>
                                <th>Продавец</th>
                                <th>Продукт</th>
                                <th>РРЦ</th>
                                <th>Цена</th>
                                <th>Маркетплейс</th>
                                <th>Просмотрено</th>
                                <th>Дата</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dumpingList.map(e => <Row key={e.ID} data={e} handler={openHandler} />)}
                        </tbody>
                    </table>
                </div>
            </div>
            {m ? <ModalFS close={closeHandler} body={<DumpingDetailsCompany data={d} type={s} close={closeHandler} />} /> : null }
        </>
    )
}

const Row = ({data, handler}) => {
    return (
        <tr onClick={() => handler(data)}>
            <td>{data.b_seller.name}</td>
            <td>{data.b_product.title}</td>
            <td>{data.productPrice}</td>
            <td>{data.parsePrice}</td>
            <td>{data.shopCode}</td>
            <td><div className={data.view ? "dot final" : "dot red"}></div></td>
            <td>{data.update}</td>
        </tr>
    )
}

const DumpingDetailsCompany = ({data, close, type}) => {
    const dispatch = useDispatch()

    const dumpingReqFilters = useSelector((state) => state.dumpingData.dumpingReqFilters)

    const [match, setMatch] = useState(false)

    useEffect(() => {
        if (data.checkedMatch) {
            setMatch(true)
        }
    }, [data])

    const handler = () => {
        let putData = {
            ID: data.ID,
            view: !data.view,
            dumpingType: type,
            checkedMatch: match
        }
        dispatch(putDumpingItem(putData, dumpingReqFilters))
        close()
    }

    return (
        <div>
            <h2>{data.b_product.title}</h2>
            <h3>{data.b_seller.name}</h3>
            <p>Ссылка: <a href={data.url} target="_blank" rel="noreferrer">{data.url}</a></p>
            <p>Дата: {data.update}</p>
            <h2>РРЦ: {data.productPrice}</h2>
            <h2>Цена в магазине: {data.parsePrice}</h2>
            <div className="mb-12">
                <label htmlFor={"active"}>
                        <input 
                            id={"active"} 
                            checked={match}
                            onChange={e => setMatch(e.target.checked)} 
                            type="checkbox"/>
                        Не совпадает на разных ПК
                    </label>
            </div>
            <div>
                <button onClick={handler} className="button">Отметить {data.view ? "непросмотренным" : "просмотренным"}</button>
            </div>
            {data.targetMin && data.targetMax ? <p>Диапазон в прайсере: {data.targetMin} - {data.targetMax}</p> : null}
            <p>Маркетплейс: {data.shopCode}</p>
            {data.screenShot ? <img src={`${gatewayURL}/api/v1/b/images/shot/${data.ID}/${data.screenShot}`} alt={"screenshot"} /> : null}
        </div>
    )
}