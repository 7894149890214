import { useDispatch, useSelector } from "react-redux"
import Switcher from "../../../../components/Switcher"
import { setDumpingFilters } from "../../../../store/AppStore/dumpingData"
import { useEffect, useState } from "react"
import { getSellers } from "../../../../store/SettingsStore/sellers"

const DumpingFilters = () => {
    const dispatch = useDispatch()

    const dumpingFilters = useSelector((state) => state.dumpingData.dumpingFilters)
    const sellers = useSelector((state) => state.sellers.sellers)

    const [f, setF] = useState(dumpingFilters)

    useEffect(() => {
        dispatch(getSellers())
    }, [dispatch])

    const handlerSwitch = () => {
        let nf = {...f}
        nf.view =!f.view
        setF(nf)
    }

    const handlerSeller = (e) => {
        let nf = {...f}
        nf.BSellerID = parseInt(e)
        setF(nf)
    }

    const handlerApply = () => {
        dispatch(setDumpingFilters(f))
    }


    return (
        <div>
            <h3>Фильтры</h3>
            <div className="mb-12">
                <Switcher active={f.view} handler={handlerSwitch} label="Просмотренные" />
            </div>
            
            <h3>Продавцы</h3>

            <div className="mb-24">
                <div>
                    <input type="radio"
                        id={`p_0`} 
                        name="link" 
                        value={0}
                        onChange={() => handlerSeller(0)} 
                        checked={f.BSellerID === 0} />
                    <label htmlFor={`p_0`}>Все</label></div>

                {sellers.map((e, i) => <div key={i}><input type="radio"
                                                id={`p_${e.ID}`} 
                                                name="link" 
                                                value={`${e.ID}`}
                                                onChange={(el) => handlerSeller(el.target.value)} 
                                                checked={e.ID === f.BSellerID} />
                                            <label htmlFor={`p_${e.ID}`}>{e.name}</label></div>)}
            </div>

            <div>
                <button className="button" onClick={handlerApply}>Применить</button>
            </div>
        </div>
    )
}

export default DumpingFilters