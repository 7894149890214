import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"
import { useNavigate, useParams } from "react-router-dom"
import { getCompanyProducts, getSellerCompany, setShowAll } from "../../../../store/SettingsStore/seller_company"
import Preloader from "../../../../components/Preloader"
import Switcher from "../../../../components/Switcher"

const SellerCompany = () => {

    const dispatch = useDispatch()

    const {id, cid} = useParams()

    const company = useSelector((state) => state.seller_company.company)

    useEffect(() => {
        dispatch(getSellerCompany(id, cid))
        dispatch(getCompanyProducts(id, cid))
    }, [dispatch, id, cid])

    if (!company) {
        return <Preloader />
    }

    return (
        <>
            <TopBlock id={id} cid={cid} />
            <ProductsBlock />
        </>
    )
}

const TopBlock = () => {

    const dispatch = useDispatch()

    const seller = useSelector((state) => state.seller.seller)
    const company = useSelector((state) => state.seller_company.company)

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle(seller.name))
        dispatch(setPageTitleBack({name: seller.name, link: `/app/settings/sellers/${seller.ID}`}))
    }, [dispatch, seller])

    return (
        <>
            <h2>{company.company.name}</h2>
        </>
    )
}

const ProductsBlock = () => {

    const dispatch = useDispatch()

    const companyProducts = useSelector((state) => state.seller_company.companyProducts)
    const showAll = useSelector((state) => state.seller_company.showAll)

    const showAllHandler = () => {
        dispatch(setShowAll(!showAll))
    }

    return(
       <>
           <h3>Товары</h3>

            <div className="mb-12">
                <Switcher active={!showAll} label="Только не связанные товары" handler={showAllHandler} />
            </div>

           <table className="table">
                <thead>
                    <tr>
                        <th>Название</th>
                        <th>Состояние у компании</th>
                        <th>Связь</th>
                    </tr>
                </thead>
                <tbody>
                    {companyProducts.map((product, i) => <ProductRow key={i} data={product} />)}
                </tbody>
            </table>
       </>
   )
}

const ProductRow = ({data}) => {

    const navigate = useNavigate()

    const showAll = useSelector((state) => state.seller_company.showAll)

    if (!showAll && data.b_product) {
        return null
    }

    return (
        <tr onClick={() => navigate(`${data.product.ID}`)}>
           <td>{data.product.title}</td>
           <td><div className={data.product.active ? "dot final" : "dot red"}></div></td>
           <td><div className={data.b_product ? "dot final" : "dot red"}></div></td>
       </tr>
    )
}

export default SellerCompany