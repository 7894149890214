import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  //responsive: true,
  //maintainAspectRatio: false,
  plugins: {
      legend: {
          display: false,
      },
  }
}

const PieGraph = ({data}) => {
  return (
      <Pie data={data} options={options} />
  )
}

export default PieGraph