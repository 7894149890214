import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
axios.defaults.withCredentials = true;

export const productsData = createSlice({
    name: 'productsData',
    initialState: {
        list : null,
        selected: [],
        analyseGraphicPeriod: "week",
        analyseGraphic: null,
        analyseRoundByProducts: null,
        analyseTableByProducts: null,
        analyseRoundBySellers: null,
        analyseTableBySellers: null
    },
    reducers: {
        setProdDataList: (state, action) => {
            state.list = action.payload
        },
        setProdDataSelected: (state, action) => {
            state.selected = action.payload
        },
        setProductsDataAnalyseGraph: (state, action) => {
            state.analyseGraphic = action.payload
        },
        setProductsDataAnalyseGraphPeriod: (state, action) => {
            state.analyseGraphicPeriod = action.payload
        },
        setPAnalyseRoundByProducts: (state, action) => {
            state.analyseRoundByProducts = action.payload
        },
        setPAnalysetableByProducts: (state, action) => {
            state.analyseTableByProducts = action.payload
        },
        setAnalyseRoundBySellers: (state, action) => {
            state.analyseRoundBySellers = action.payload
        },
        setAnalysetableBySellers: (state, action) => {
            state.analyseTableBySellers = action.payload
        }
    }
})

export const { setProdDataList, setProdDataSelected, setProductsDataAnalyseGraph, setProductsDataAnalyseGraphPeriod, setPAnalyseRoundByProducts, setPAnalysetableByProducts, setAnalyseRoundBySellers, setAnalysetableBySellers } = productsData.actions

export const getProdDataList = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/analyse/products/list`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setProdDataList(response.data.result.data))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getProductsDataAnalyseGraph = (period, selected) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/products/graph`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
           "period" : period,
           "products" : selected
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setProductsDataAnalyseGraph(response.data.result))
        })
        .catch((error) => {
            dispatch(setProductsDataAnalyseGraph(null))
        });
}

export const getPAnalyseRoundByProducts = (f, t, selected) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/products/products/graph`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            from: f,
            to: t,
            products: selected
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setPAnalyseRoundByProducts(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getPAnalysetableByProducts = (f, t, selected) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/products/products/table`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            from: f,
            to: t,
            products: selected
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setPAnalysetableByProducts(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getAnalyseRoundBySellers = (f, t, selected) => (dispatch, getState) => {

    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/products/sellers/graph`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            from: f,
            to: t,
            products: selected
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setAnalyseRoundBySellers(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getAnalysetableBySellers = (f, t, selected) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/analyse/products/sellers/table`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            from: f,
            to: t,
            products: selected
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setAnalysetableBySellers(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}


export default productsData.reducer