import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { updateMessagesList } from '../System/messages';
axios.defaults.withCredentials = true;

export const sellerCompany = createSlice({
    name: 'sellerCompany',
    initialState: {
        company: null,
        companyProducts: [],
        showAll: true,
        companyProduct: null,
        alertProduct: null
    },
    reducers: {
        setCompany: (state, action) => {
            state.company = action.payload
        },
        setCompanyProducts: (state, action) => {
            state.companyProducts = action.payload
        },
        setShowAll: (state, action) => {
            state.showAll = action.payload
        },
        setCompanyProduct: (state, action) => {
            state.companyProduct = action.payload
        },
        setAlertProduct: (state, action) => {
            state.alertProduct = action.payload
        },
    }
})

export const { setCompany, setCompanyProducts, setShowAll, setCompanyProduct, setAlertProduct } = sellerCompany.actions

export const getSellerCompany = (sid, cid) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/sellers/${sid}/companies/${cid}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setCompany(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const getCompanyProducts = (sid, cid) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/sellers/${sid}/companies/${cid}/products`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result.seller_company_products) {
                dispatch(setCompanyProducts(response.data.result.seller_company_products))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setCompanyProducts([]))
        });
}

export const getCompanyProduct = (sid, cid, pid) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/sellers/${sid}/companies/${cid}/products/${pid}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setCompanyProduct(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setCompanyProduct(null))
        });
}

export const linkCompanyProduct = (sid, cid, pid, data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/sellers/${sid}/companies/${cid}/products/${pid}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getCompanyProduct(sid, cid, pid))
            dispatch(updateMessagesList(
                {
                    message: "ok",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const getAlertProduct = (sid, cid, pid) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/sellers/${sid}/companies/${cid}/products/${pid}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setAlertProduct(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setAlertProduct(null))
        });
}

export default sellerCompany.reducer